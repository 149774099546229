.PPcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
  gap: 1rem;
}

.pp-left-description {
  width: 50%;
  padding-left: 2.5rem;
}

.pp-left-padding {
  padding: 1rem;
}

.management-container h3 {
  font-size: large;
}
.PPdecription-container {
  display: flex;
  gap: 3rem;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.pp-hero-container {
  width: 50%;
  height: 100%;
}

.pp-hero-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-pp {
  font-weight: 500;
  padding: 2rem;
  background-color: lightskyblue;
  border-radius: 1rem;
  text-align: left;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
}

.card-pp div img {
  width: 100%;
  height: 100%;
}
.pp-card-innertext {
  width: 50%;
  font-size: larger;
}

.card-pp p {
  color: black;
}
.Ms-contaioner {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.ms-box {
  position: relative;
  flex: 1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 1rem;
  overflow: hidden; /* Ensure pseudo-element doesn't overflow */
}

.ms-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/src/assets/license.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.6;
  z-index: 1;
}

.ms-box p {
  z-index: 2;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: black;
  font-size: 1.5rem !important;
}

.plus-icon-container {
  width: 50px;
}

.ms-box2 {
  position: relative;
  flex: 1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 1rem;
  overflow: hidden; /* Ensure pseudo-element doesn't overflow */
}

.ms-box2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/src/assets/help.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
  z-index: 1;
}

.ms-box2 p {
  z-index: 2;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: black;
  font-size: 1.5rem !important;
}

.ms-box2 img {
  z-index: 2;
  opacity: 0.6;
}

.ms-box2 {
  position: relative;
  flex: 1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 1rem;
  overflow: hidden; /* Ensure pseudo-element doesn't overflow */
}

.ms-box3 {
  position: relative;
  flex: 1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 1rem;
  overflow: hidden; /* Ensure pseudo-element doesn't overflow */
}

.ms-box3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/src/assets/undraw_upgrade_re_gano.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
  z-index: 1;
}

.ms-box3 p {
  z-index: 2;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: black;
  font-size: 1.5rem !important;
}

.ms-box3 img {
  z-index: 2;
  opacity: 0.6;
}

li {
  text-align: left;
}

.wrap-r {
  flex-wrap: nowrap !important;
}
.r-padding {
  padding: 0 !important;
}

.management-container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: -1;
}

.ml-container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.bg-blur {
  background: rgba(0, 157, 255, 0.6) !important;
  backdrop-filter: blur(15px);
}

.tabs {
  width: 90%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  margin-top: 3rem;
  padding-top: 0;
  height: auto;
  border-top: 1px solid black;
}

.tab-titles {
  display: flex;
}

.tab-title {
  flex: 1;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px 4px 0 0;
}

.tab-title:hover {
  background-color: #f0f0f0;
}

.tab-title.active {
  background-color: #007bff;
  color: #fff;
  border-bottom: 2px solid #007bff; /* Highlight the active tab */
}

.tab-content {
  padding: 1rem;
  background: lightskyblue;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

.section {
  margin-bottom: 20px;
}

.section-heading {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.section-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.section-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.section-item:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 833px) {
  .plus-icon-container {
    display: none;
  }
  .Ms-contaioner {
    flex-direction: column;
    width: 90%;
    height: 80vh;
  }
  .ms-box,
  .ms-box2,
  .ms-box3 {
    width: 100%;
  }
  .PPdecription-container {
    flex-direction: column-reverse;
  }
  .pp-hero-container {
    width: 100%;
  }
  .pp-left-description {
    width: 100%;
    padding-left: 0;
  }
  .tab-titles {
    font-size: smaller;
  }

  .sqs-block-content {
    width: 90% !important;
  }
  .card-pp {
    padding: 1rem;
    font-size: small;
  }

}



.msp-container {
  padding: 40px; /* Increased padding for better spacing */
  border-radius: 10px; /* Rounded corners for the main container */
}

.home-title {
  text-align: center;
  font-size: 2.5em; /* Larger font size for the title */
  color: #333; /* Darker color for better readability */
}

.msp-section,
.msp-benefits,
.pricing,
.monthly-support,
.computer-rental,
.additional-services,
.computers-laptops {
  margin-bottom: 40px; /* Increased margin for more space between sections */
}

h2 {
  text-align: center; /* Center the section headings */
  font-size: 2em; /* Increased font size for headings */
  color: #444; /* Darker heading color */
  margin-bottom: 20px; /* Space below headings */
  text-transform: uppercase; /* Uppercase for emphasis */
  letter-spacing: 1px; /* Slight spacing for a modern look */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Space between cards */
  justify-content: center; /* Center cards in the container */
}

.card {
  background: lightblue; /* Gradient background */
  border: 1px solid #ddd; /* Light border */
  border-radius: 10px; /* Rounded corners */
  padding: 0.5rem; /* Increased padding for cards */
  width: 280px; /* Slightly wider cards */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Deeper shadow for more depth */
  text-align: center; /* Center text in cards */
  transition: transform 0.3s, box-shadow 0.3s; /* Animation for hover effects */
}

.card img {
  margin-top: 10px;
  width: 100%;
  height: 70px;
  object-fit: contain;
}

.card:hover {
  transform: translateY(-8px); /* Lift effect on hover */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.card h3 {
  color: #333; /* Dark color for card headings */
  font-size: medium; /* Larger font size for card headings */
  margin-bottom: 10px; /* Space below card headings */
}

.card p {
  color: #555; /* Lighter color for card text */
  font-size: 1em; /* Standard font size for card text */
}


@media only screen and (max-width: 400px) {
  .tab-titles {
    font-size: 9px !important;
  }
  .msp-container{
    padding: 1rem;
  }
  .card{
    width: 100%;
  }
}